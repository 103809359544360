import axios from "axios"
import { APIURL, TIMEOUT } from "./systemparameter"
import { getUserAuthToken, refreshUserSession, storeSystemParameter, SYSPARAM } from "./usersession"
import { reportError } from "./utility"

export const loadSystemParameters = async () => {
    return await Promise.all([
        //loadSystemParametersByType("project"),
        loadOtherSystemParameters(),
    ])
}

const loadSystemParametersByType = async (parameterType) => {
    return await axios.get(`${APIURL}${parameterType}/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( param => {
                if(param.name in SYSPARAM) reportError(`Duplicated system parameter detected: ${param.name}`)
                storeSystemParameter(param.name, param.pkey)
            })
        })
        .catch( error => {
            reportError(error, `Failed to load system parameters: ${parameterType}`)
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
}

const loadOtherSystemParameters = async () => {
    return await axios.get(`${APIURL}othersystemparameter/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( param => {
                if(param.name in SYSPARAM) reportError(`Duplicated system parameter detected: ${param.name}`)
                storeSystemParameter(param.name, param.value)
            })
        })
        .catch( error => {
            reportError(error, "Failed to load system parameters: othersystemparameter")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
}