import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, Pagination, Spin } from 'antd'
import { PlusOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../common/systemparameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { reportError } from "../common/utility"
import { formLayout_2Columns } from "../common/layout"
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import NotificationMessageTypeSelect from '../common/notificationmessagetypeselect'


const { Panel } = Collapse
const { RangePicker } = DatePicker

const SupplementaryMessageTable = () => {
    const [form] = Form.useForm()
    
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([dayjs().subtract(1, "months"), dayjs().add(1, 'months')])
    const [notificationMessageTypeId, setNotificationMessageTypeId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
        
    const [messageDataSource, setMessageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    const searchMessage = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}supplementarymessage/`, {
            params: { 
                effective_from_date: dateRange[0].format(UNIDATEFORMAT),
                effective_to_date: dateRange[1].format(UNIDATEFORMAT),
                notification_message_type: notificationMessageTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search supplementary message record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( message => {
            data.push({
                key: message.id,
                effectiveFrom: dayjs(message.effective_from_datetime).format(DATETIMEFORMAT),
                effectiveFromUnformatted: dayjs(message.effective_from_datetime).format(UNIDATETIMEFORMAT),
                effectiveTo: dayjs(message.effective_to_datetime).format(DATETIMEFORMAT),
                effectiveToUnformatted: dayjs(message.effective_to_datetime).format(UNIDATETIMEFORMAT),
                notificationMessageTypeId: message.notification_message_type_data.pKey,
                notificationMessageType: message.notification_message_type_data.name,
                message: message.message
            })
        })
        
        setMessageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchMessage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchMessage(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate({ pathname: "/supplementarymessagenew" })
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/supplementarymessageupdate", 
                    search: `?id=${record.key}&notificationMessageType=${record.notificationMessageType}`
                }) 
            }
        }
    }

    const onSearchNotificationMessageTypeChange = (e, value) => {
        setNotificationMessageTypeId(e)
    }

    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    useEffect(() => {
        searchMessage(currentPage)
    }, [])

    const columns = [
        { title: 'Effective From', dataIndex: 'effectiveFrom', key: 'effectiveFrom', sorter: (a, b) => a.effectiveFrom.localeCompare(b.effectiveFrom) },
        { title: 'Effective To', dataIndex: 'effectiveTo', key: 'effectiveTo', sorter: (a, b) => a.effectiveTo.localeCompare(b.effectiveTo) },
        { title: 'Message Type', dataIndex: 'notificationMessageType', key: 'notificationMessageType', sorter: (a, b) => a.notificationMessageType.localeCompare(b.notificationMessageType) },
        { title: 'Message', dataIndex: 'message', key: 'message', },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Supplementary Message Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <Form.Item name="notificationMessageTypeId" label="Notification Message Type">
                                <NotificationMessageTypeSelect withBlank={true} onChange={onSearchNotificationMessageTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={messageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default SupplementaryMessageTable