import { Row, Col, Space, Button, Collapse, message, Spin, DatePicker, InputNumber, Select } from 'antd'
import { FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'
import DistrictSelect from '../common/districtselect'

const { Panel } = Collapse


const SRReverificationWithPhotosReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [firstVerifyYear, setFirstVerifyYear] = useState(dayjs().add(-1, 'years').year())
    const [srDateFrom, setSrDateFrom] = useState(dayjs().add(-1, 'years').month(0).date(1))
    const [srDateTo, setSrDateTo] = useState(dayjs().add(-1, 'years').month(11).date(31))
    const [reverifyDateFrom, setReverifyDateFrom] = useState(dayjs())
    const [reverifyDateTo, setReverifyDateTo] = useState(dayjs())
    const [districtCodes, setDistrictCodes] = useState([])
    const [isLoading, setIsLoading] = useState(false)
        
    const downloadReport = () => {
        if(srDateTo.isBefore(srDateFrom)) {
            message.info("Invalid SR Date To.")
            return
        }

        if(reverifyDateTo.isBefore(reverifyDateFrom)) {
            message.info("Invalid Reverify Date To.")
            return
        }

        if(districtCodes.length == 0) {
            message.info("Please select at least one district.")
            return
        }

        setIsLoading(true)
                        
        axios.get(`${APIURL}srreverificationwithphotosreport/`, {
            params: { 
                sr_date_from: srDateFrom.format(UNIDATEFORMAT),
                sr_date_to: srDateTo.format(UNIDATEFORMAT),
                first_verify_year: firstVerifyYear,
                reverify_date_from: reverifyDateFrom.format(UNIDATEFORMAT),
                reverify_date_to: reverifyDateTo.format(UNIDATEFORMAT),
                district_codes: districtCodes.toString(),
            },
            responseType: "blob",
            timeout: 20 * 60 * 1000,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `SR Reverification With Photos Report ${now}.zip`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onFirstVerifyYearChange = (year) => {
        setFirstVerifyYear(year)
    }

    const onSrDateFromChange = (date) => {
        setSrDateFrom(date)
    }

    const onSrDateToChange = (date) => {
        setSrDateTo(date)
    }

    const onReverifyDateFromChange = (date) => {
        setReverifyDateFrom(date)
    }

    const onReverifyDateToChange = (date) => {
        setReverifyDateTo(date)
    }

    const onDistrictCodesChange = (e) => {
        setDistrictCodes(e)
    }

    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>SR Date From:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onSrDateFromChange} defaultValue={srDateFrom} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>SR Date To:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onSrDateToChange} defaultValue={srDateTo} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>First Verify Year:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <InputNumber min={2020} max={9999} defaultValue={firstVerifyYear} onChange={onFirstVerifyYearChange} />
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Reverify Date From:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onReverifyDateFromChange} defaultValue={reverifyDateFrom} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Reverify Date To:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onReverifyDateToChange} defaultValue={reverifyDateTo} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>District(s):</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DistrictSelect withBlank={false} allowClear={true} onChange={onDistrictCodesChange} marginLeft={0} />
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={() => downloadReport()} icon={<FileExcelOutlined />} />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default SRReverificationWithPhotosReportTable