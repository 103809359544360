import { Row, Col, Space, Button, Collapse, message, Spin, DatePicker, TimePicker } from 'antd'
import { FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, HOURMINUTESECOND, LOADING, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'

const { Panel } = Collapse


const SRVerificationReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [cutOffDate, setCutOffDate] = useState(dayjs())
    const [dateFrom, setDateFrom] = useState(dayjs().add(-1, 'days'))
    const [dateTo, setDateTo] = useState(dayjs().add(-1, 'days'))
    const [cutOffTime, setCutOffTime] = useState(dayjs().hour(9).minute(0).second(0))
    const [isLoading, setIsLoading] = useState(false)
        
    const downloadReport = () => {
        if(dateTo.isBefore(dateFrom)) {
            message.info("Invalid SR Date To.")
            return
        }

        // 231214
        // Allow same day cut-off to view report of "today".
        //if(cutOffDate.isBefore(dateTo) || cutOffDate.isSame(dateTo)){
        if(cutOffDate.isBefore(dateTo)){
            message.info("Invalid Cut Off Date.")
            return
        }

        setIsLoading(true)
                        
        axios.get(`${APIURL}srverificationreport/`, {
            params: { 
                date_from: dateFrom.format(UNIDATEFORMAT),
                date_to: dateTo.format(UNIDATEFORMAT),
                cutoff_date: cutOffDate.format(UNIDATEFORMAT),
                cutoff_time: cutOffTime.format(HOURMINUTESECOND)
            },
            responseType: "blob",
            timeout: 60 * 5000,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `SR Verification Report ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCutOffDateChange = (date) => {
        setCutOffDate(date)
    }

    const onDateFromChange = (date) => {
        setDateFrom(date)
    }

    const onDateToChange = (date) => {
        setDateTo(date)
    }

    const onCutOffTimeChange = (time, timeString) => {
        setCutOffTime(time)
    }

    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>SR Date From:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onDateFromChange} defaultValue={dateFrom} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>SR Date To:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onDateToChange} defaultValue={dateTo} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Cut Off Date:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <DatePicker onChange={onCutOffDateChange} defaultValue={cutOffDate} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/>
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Cut Off Time :</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 10 }}>
                                {/* <DatePicker showTime onChange={onCutOffTimeChange} defaultValue={cutOffTime} format={HOURMINUTESECOND} style={{ width: SEARCHOPTIONVIEWWIDTH }} allowClear={false}/> */}
                                <TimePicker onChange={onCutOffTimeChange} defaultValue={dayjs('09:00:00', 'HH:mm:ss')} style={{ width: SEARCHOPTIONVIEWWIDTH }}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={14} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={() => downloadReport()} icon={<FileExcelOutlined />} />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default SRVerificationReportTable