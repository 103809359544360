import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./login"
import ChangePassword from "../setup/changepassword"
import UserMain from "../setup/usermain"
import UserNew from "../setup/usernew"
import UserUpdate from "../setup/userupdate"
import { MENUPATH_CASESTATUS_OUTSTANDINGCASES_REPORT, MENUPATH_SRVERIFICATIONREPORT, MENUPATH_SR_REVERIFICATION_WITH_PHOTOS_REPORT, MENUPATH_SUPPLEMENTARY_MESSAGE } from "./systemparameter"
import SRVerificationReportMain from "../srverificationreport/srverificationreportmain"
import CaseStatusReportMain from "../casestatusreport/casestatusreportmain"
import SRReverificationWithPhotosReportMain from "../srreverificationwithphotosreport/srreverificationwithphotosreportmain"
import SupplementaryMessageMain from "../supplementarymessage/supplementarymessagemain"
import SupplementaryMessageNew from "../supplementarymessage/supplementarymessagenew"
import SupplementaryMessageUpdate from "../supplementarymessage/supplementarymessageupdate"


const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Login />} />

            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/user" element={<UserMain />} />
            <Route path="/usernew" element={<UserNew />} />
            <Route path="/userupdate" element={<UserUpdate />} />

            <Route path={MENUPATH_CASESTATUS_OUTSTANDINGCASES_REPORT} element={<CaseStatusReportMain />} />
            <Route path={MENUPATH_SRVERIFICATIONREPORT} element={<SRVerificationReportMain />} />
            <Route path={MENUPATH_SR_REVERIFICATION_WITH_PHOTOS_REPORT} element={<SRReverificationWithPhotosReportMain />} />

            <Route path={MENUPATH_SUPPLEMENTARY_MESSAGE} element={<SupplementaryMessageMain />} />
            <Route path="/supplementarymessagenew" element={<SupplementaryMessageNew />} />
            <Route path="/supplementarymessageupdate" element={<SupplementaryMessageUpdate />} />
            
        </Routes>
    </BrowserRouter>
)

export default Router