import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Descriptions, Spin, DatePicker, Input } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATETIMEFORMAT, LOADING, MENUPATH_SUPPLEMENTARY_MESSAGE, UNIDATETIMEFORMAT } from "../common/systemparameter"
import { reportError } from "../common/utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { formLayout_2Columns } from "../common/layout"
import dayjs from 'dayjs'
import NotificationMessageTypeSelect from '../common/notificationmessagetypeselect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { RangePicker } = DatePicker
const { TextArea } = Input


const SupplementaryMessageNew = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [effectiveDateRange, setEffectiveDateRange] = useState([dayjs(), dayjs()])
    const [notificationMessageTypeId, setNotificationMessageTypeId] = useState(0)
            
    const createMessage = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            const effectiveFrom = effectiveDateRange[0].second(0)
            const effectiveTo = effectiveDateRange[1].second(59)

            axios.post(`${APIURL}supplementarymessage/create/`, {
                effective_from_datetime: effectiveFrom.format(UNIDATETIMEFORMAT),
                effective_to_datetime: effectiveTo.format(UNIDATETIMEFORMAT),
                notification_message_type: notificationMessageTypeId,
                message: values.message,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Supplementary message saved.")
                navigate({ 
                    pathname: MENUPATH_SUPPLEMENTARY_MESSAGE
                })
            })
            .catch( error => {
                reportError(error, `Failed to save supplementary message.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            setIsLoading(false)
            message.warning("Required field validation failed.")
            return
        })
    }

    const onSave = (e) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save message record confirmed?</p></Space>,
            onOk() { createMessage() },
            onCancel() {},
            centered: true
        })
    }

    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_SUPPLEMENTARY_MESSAGE
        })
    }

    const onEffectiveDateRangeChange = (dateRange) => {
        setEffectiveDateRange(dateRange)
    }

    const onNotificationMessageTypeChange = (e) => {
        setNotificationMessageTypeId(e)
    }

    const validateNotificationMessageType = (() => {
        if(notificationMessageTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Notification message type is required."))
    })

    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Supplementary Message">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create supplementary message in automated notification</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout_2Columns}>
                    <Form.Item name="effectiveDateRange" label="Effective Date Range">
                        <RangePicker onChange={onEffectiveDateRangeChange} defaultValue={effectiveDateRange} format={DATETIMEFORMAT} showTime style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}} allowClear={false}/>
                    </Form.Item>

                    <Form.Item name="notificationMessageType" label="Notification Message Type"
                        rules={[
                            { required: true, validator: validateNotificationMessageType },
                        ]}>
                        <NotificationMessageTypeSelect withBlank={false} onChange={onNotificationMessageTypeChange}/>
                    </Form.Item>

                    <Form.Item name="message" label="Message" >
                        <TextArea showCount rows={3} maxLength={2000} style={{width: "635px" }}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Message:"
                    subTitle="Create supplementary message in automated notification"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SupplementaryMessageNew