import { Row, Col, Button, Drawer } from 'antd'
import { ValidateUserSession, clearUserSession, getUsername } from "./usersession"
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router"
import { useEffect, useState } from 'react'
import Menu from "./menu"

const NONMOBILEDEVICEDRAWERWIDTH = "30vw" // Default to 30% view width


const MainHeader = () => {
    // Redirect user to login page if not authenticated or session expired.
    ValidateUserSession()

    const navigate = useNavigate()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [drawerWidth, setDrawerWidth] = useState(NONMOBILEDEVICEDRAWERWIDTH) 
    // const isDesktopOrLaptop = useMediaQuery({ minWidth: MEDIAMAXWIDTH })
    // const isBigScreen = useMediaQuery({ minWidth: 1824 })
    // const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    // const isPortrait = useMediaQuery({ orientation: 'portrait' })
    // const isRetina = useMediaQuery({ minResolution: '2dppx' })
    //const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    // Drawer width based on device type.
    // AntD Drawer width does not support responsive design.
    //if(isTabletOrMobile) setDrawerWidth(MOBILEDEVICEDRAWERWIDTH)

    //----------
    // To logout
    //----------
    const onLogout = () => {
        clearUserSession()
        navigate("/")
    }

    //------------
    // Show Drawer
    //------------
    const onShowDrawer = () => {
        setDrawerVisible(true)
    }

    const onCloseDrawer = () => {
        setDrawerVisible(false)
    }

    useEffect(() => {
    })

    return(
        <Row>
            <Col span={2}>
                <Row justify="start">
                    <Col>
                        <Button type="primary" htmlType="button" onClick={onShowDrawer}><MenuOutlined /></Button>    
                        <Drawer title={`Hi! ${getUsername()}`} placement="left" closable={false} onClose={onCloseDrawer} open={drawerVisible} width={drawerWidth}>
                            <Menu setDrawerVisible={setDrawerVisible}/> 
                        </Drawer>
                    </Col>
                </Row>
            </Col>
            <Col span={20}>
                <Row justify="center">
                    <Col>
                        
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row justify="end">
                    <Col>
                        <Button danger type="primary" htmlType="button" onClick={onLogout}><LogoutOutlined /></Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MainHeader