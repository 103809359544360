import { useCookies } from "react-cookie"
import { useState } from 'react'
import { Menu, message } from 'antd'
import { SettingOutlined, UserOutlined, ProfileOutlined, FormatPainterOutlined, MessageOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router"
import { DARKTHEMEBACKGROUNDCOLOR, DARKTHEMEBORDERCOLOR, LIGHTTHEMEBACKGROUNDCOLOR, LIGHTTHEMEBORDERCOLOR, MENU_CASESTATUS_OUTSTANDINGCASES_REPORT, MENU_CHANGEPASSWORD, 
    MENU_SR_REVERIFICATION_WITH_PHOTOS_REPORT, 
    MENU_SRVERIFICATIONREPORT, MENU_SUPPLEMENTARY_MESSAGE, MENU_USER, MENUKEY_CASESTATUS_OUTSTANDINGCASES_REPORT, MENUKEY_CHANGEPASSWORD, MENUKEY_SR_REVERIFICATION_REPORT, MENUKEY_SR_REVERIFICATION_WITH_PHOTOS_REPORT, MENUKEY_SRVERIFICATIONREPORT, MENUKEY_SUPPLEMENTARY_MESSAGE, MENUKEY_THEMESWITCH, 
    MENUKEY_USER, MENUPATH_CASESTATUS_OUTSTANDINGCASES_REPORT, MENUPATH_CHANGEPASSWORD, MENUPATH_SR_REVERIFICATION_WITH_PHOTOS_REPORT, MENUPATH_SRVERIFICATIONREPORT, MENUPATH_SUPPLEMENTARY_MESSAGE, MENUPATH_USER } from './systemparameter'

import { getUserGroup, OTHERSYSPARAM, storeBackgroundColor, storeBorderColor } from './usersession'

const { SubMenu } = Menu

const ACISMenu = ({setDrawerVisible}) => {
    const navigate = useNavigate()

    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [themeLabel, setThemeLabel] = useState(cookies['useDarkTheme'] == '1' ? 'Light Theme' : 'Dark Theme')
    
    //-------------
    // Handle click
    //-------------
    const onClick = (e) => {
        // Close drawer
        setDrawerVisible(false) 
        
        switch(parseInt(e.key)) {
            case MENUKEY_CHANGEPASSWORD:
                navigate(MENUPATH_CHANGEPASSWORD)
                break
            case MENUKEY_USER:
                navigate(MENUPATH_USER)
                break

            case MENUKEY_CASESTATUS_OUTSTANDINGCASES_REPORT:
                navigate(MENUPATH_CASESTATUS_OUTSTANDINGCASES_REPORT)
                break
            case MENUKEY_SRVERIFICATIONREPORT:
                navigate(MENUPATH_SRVERIFICATIONREPORT)
                break

            case MENUKEY_SR_REVERIFICATION_WITH_PHOTOS_REPORT:
                navigate(MENUPATH_SR_REVERIFICATION_WITH_PHOTOS_REPORT)
                break

            case MENUKEY_SUPPLEMENTARY_MESSAGE:
                navigate(MENUPATH_SUPPLEMENTARY_MESSAGE)
                break
            
            case MENUKEY_THEMESWITCH:
                if(cookies['useDarkTheme'] == '1') {
                    setCookies('useDarkTheme', '0', { path: '/', SameSite: 'Strict', expires: new Date(9999, 12, 31) })
                    storeBackgroundColor(LIGHTTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(LIGHTTHEMEBORDERCOLOR)
                }
                else {
                    setCookies('useDarkTheme', '1', { path: '/', SameSite: 'Strict', expires: new Date(9999, 12, 31) })
                    storeBackgroundColor(DARKTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(DARKTHEMEBORDERCOLOR)
                }

                window.location.reload()
                break
            default: 
                message.error("Page not found.")
        }
    }

    return (
        <Menu onClick={onClick} mode="inline" /*defaultSelectedKeys={['1']}*/ defaultOpenKeys={["reportMenu"]}>
            <SubMenu key="userMenu" icon={<UserOutlined />} title="Account">
                <Menu.Item key={MENUKEY_CHANGEPASSWORD}>{MENU_CHANGEPASSWORD}</Menu.Item>
                { getUserGroup().includes(OTHERSYSPARAM("ADMIN")) &&
                    <>
                        <Menu.Item key={MENUKEY_USER}>{MENU_USER}</Menu.Item>
                    </>
                }
            </SubMenu>

            <SubMenu key="reportMenu" icon={<ProfileOutlined />} title="MCDC Reports">
                <Menu.Item key={MENUKEY_CASESTATUS_OUTSTANDINGCASES_REPORT}>{MENU_CASESTATUS_OUTSTANDINGCASES_REPORT}</Menu.Item>
                <Menu.Item key={MENUKEY_SRVERIFICATIONREPORT}>{MENU_SRVERIFICATIONREPORT}</Menu.Item>
                <Menu.Item key={MENUKEY_SR_REVERIFICATION_WITH_PHOTOS_REPORT}>{MENU_SR_REVERIFICATION_WITH_PHOTOS_REPORT}</Menu.Item>
            </SubMenu>

            <Menu.Item key={MENUKEY_SUPPLEMENTARY_MESSAGE} icon={<MessageOutlined />}>{MENU_SUPPLEMENTARY_MESSAGE}</Menu.Item>
                        
            { getUserGroup().includes(OTHERSYSPARAM("ADMIN")) &&
                <>
                <SubMenu key="setupMenu" icon={<SettingOutlined />} title="Setup">
                    
                    <Menu.Item key={MENUKEY_THEMESWITCH} icon={<FormatPainterOutlined />}>{themeLabel}</Menu.Item>
                </SubMenu>
                </>
            }
        </Menu>
  )
}

export default ACISMenu