import { useNavigate } from "react-router"
import { message } from 'antd'
import moment from 'moment'
import { UNIDATETIMEFORMAT } from "./systemparameter"


const AUTHTOKEN_KEY = "authToken"
const USERGROUPS_KEY = "userGroups"
const USERID_KEY = "userID"
const USERNAME_KEY = "userName"
const LASTACTIVE_KEY = "lastActive"

const BACKGROUNDCOLOR_KEY = "backgroundColor"
const BORDERCOLOR_KEY = "borderColor"


export const createUserSession = (userId, username, userGroups, authToken) => {
    // Each user session must contain at least the following items.
    window.sessionStorage.setItem(AUTHTOKEN_KEY, authToken)
    window.sessionStorage.setItem(USERID_KEY, userId)
    window.sessionStorage.setItem(USERNAME_KEY, username)
    window.sessionStorage.setItem(USERGROUPS_KEY, userGroups)
    window.sessionStorage.setItem(LASTACTIVE_KEY, new Date())
}

// React Hook (useHistory) can only be called from React function component so function name must start with Capital letter.
export const ValidateUserSession = () => {
    const navigate = useNavigate()

    // Redirect user to login page if not authenticated or session expired.
    const lastActive = moment(window.sessionStorage.getItem(LASTACTIVE_KEY))
    
    if(window.sessionStorage.getItem(AUTHTOKEN_KEY) === null || lastActive.add(parseInt(OTHERSYSPARAM("USER_SESSION_HOUR")), 'hours').isBefore(moment())) {
        console.log('HERE')
        navigate({ pathname: "/" })
        //message.info("Please kindly login.")
        return false
    }

    return true
}

export const getUserAuthToken = () => {
    return window.sessionStorage.getItem(AUTHTOKEN_KEY)
}

export const refreshUserSession = () => {
    window.sessionStorage.setItem(LASTACTIVE_KEY, moment().format(UNIDATETIMEFORMAT))
}

export const clearUserSession = () => {
    window.sessionStorage.clear()
}

export const getUserId = () => {
    return window.sessionStorage.getItem(USERID_KEY)
}

export const getUsername = () => {
    return window.sessionStorage.getItem(USERNAME_KEY)
}

export const getUserGroup = () => {
    try{
        return window.sessionStorage.getItem(USERGROUPS_KEY).split(",")
    }
    catch(error) {
        window.location.href = "/"
    }
}

// System parameters from database
export const storeSystemParameter = (key, value) => {
    if(window.sessionStorage.getItem(key) !== null) {
        message.error(`Duplicated system parameter detected: ${key}: ${value}.`)
        return
    }

    if(!Array.isArray(value))
        window.sessionStorage.setItem(key, value)
    else
        window.sessionStorage.setItem(key, JSON.stringify(value))
}

// Always return an interger (primary key)
export const SYSPARAM = (key) => {
    try{
        if(window.sessionStorage.getItem(key) === null) {
            const sessionValid = ValidateUserSession()
            if(sessionValid) 
                message.error(`Unknown system parameter requested: ${key}`)
            else
                window.location.href = "/"
        }

        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        }
        catch (e) {
            return parseInt(window.sessionStorage.getItem(key))
        }
    }
    catch(error) {
        window.location.href = "/"
        console.log(`Error. Unknown system parameter requested.`)
    }
}

// Always return string
export const OTHERSYSPARAM = (key) => {
    try{
        if(window.sessionStorage.getItem(key) === null) {
            const sessionValid = ValidateUserSession()
            if(sessionValid) 
                message.error(`Unknown other system parameter requested: ${key}`)
            else
                window.location.href = "/"
        }

        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        }
        catch (e) {
            return window.sessionStorage.getItem(key)
        }
    }
    catch(error) {
        window.location.href = "/"
        console.log(`Error. Unknown other system parameter requested.`)
    } 
}

export const storeBackgroundColor = (colorCode) => {
    window.sessionStorage.setItem(BACKGROUNDCOLOR_KEY, colorCode)
}

export const storeBorderColor = (color) => {
    window.sessionStorage.setItem(BORDERCOLOR_KEY, color)
}

export const retrieveBackgroundColor = () => {
    return window.sessionStorage.getItem(BACKGROUNDCOLOR_KEY)
}

export const retrieveBorderColor = () => {
    return window.sessionStorage.getItem(BORDERCOLOR_KEY)
}