import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Tag, Spin, Select } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import moment from 'moment'

const { Panel } = Collapse
const { Option } = Select


const UserTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [userId, setUserId] = useState(0)
    const [user, setUser] = useState("")
    const [userDataSource, setUserDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [userOption, setUserOption] = useState([])
    
    const searchUser = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}userpaginated/`, {
            params: { 
                id: userId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, `Failed to search user.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( user => {
            data.push({
                id: user.id,
                username: user.username,
                email: user.email,
                isActive: user.is_active,
                dateJoined: moment(user.date_joined).format(DATEFORMAT),
                groupId: user.group.id,
                group: user.group.name,
            })
        })

        setUserDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(userId != 0) searchCriteria = `${searchCriteria}\nUser: ${user}`
                                
        axios.get(`${APIURL}user/download/`, {
            params: { 
                id: userId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `User ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getUsers = () => {
        axios.get(`${APIURL}user/`, { 
            params: {
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            console.log(response)
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( user => <Option key={user.id}>{user.username}</Option>))
            setUserOption(options)
        })
        .catch( error => {
            reportError(error, `Failed to get user.`)
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchUser(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchUser(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/usernew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/userupdate", 
                    search: `?id=${record.id}&username=${record.username}&groupId=${record.groupId}&group=${record.group}&isActive=${record.isActive}`
                }) 
            }
        }
    }

    const onSearchUserChange = (e, value) => {
        if(e != undefined) {
            setUserId(e)
            setUser(value.children)
        }
        else {
            setUserId(0)
            setUser("")
        }
    }

    useEffect(() => {
        getUsers()
        searchUser(currentPage)
    }, [])

    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username', align: 'center', sorter: (a, b) => a.username.localeCompare(b.username) },
        { title: 'email', dataIndex: 'email', key: 'email', align: 'center', sorter: (a, b) => a.email.localeCompare(b.email) },
        { title: 'Group', dataIndex: 'group', key: 'group', align: 'center', sorter: (a, b) => a.group.localeCompare(b.group) },
        { title: 'Is Active', dataIndex: 'isActive', key: 'isActive', align: 'center', sorter: (a, b) => a.isActive - b.isActive,
            render: (isActive) => {
                if(isActive)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'Date Created', dataIndex: 'dateJoined', key: 'dateJoined', align: 'center', sorter: (a, b) => a.dateJoined.localeCompare(b.dateJoined) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search User" key="1">
                        <Row justify="center">
                            <>
                            <Col span={6} />
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Username :</Col>
                            <Col span={3}>
                                <Select onChange={onSearchUserChange} style={{width: SEARCHOPTIONVIEWWIDTH}} allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {userOption}
                                </Select>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={userDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default UserTable