import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from './systemparameter'
import { reportError } from './utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const DistrictSelect = ({withBlank, defaultValue, onChange, allowClear, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [districtOption, setDistrictOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
        
    const getAllDistrict = () => {
        axios.get(`${APIURL}district/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( district => <Option key={district.district_code}>{district.district_name}</Option>))
            setDistrictOption(options)
        })
        .catch( error => {
            reportError(error, "<DistrictSelect /> Failed to get districts.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllDistrict()
    }, []) 
    
    return(
        <Select mode={"multiple"} style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {districtOption}
        </Select>
    )
}

export default DistrictSelect