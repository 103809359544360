import { Row, Col, Space, Button, Collapse, message, Spin, DatePicker } from 'antd'
import { FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, SEARCHOPTIONVIEWWIDTH, UNIDATECOMPACTFORMAT, UNIDATEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'
import moment from 'moment'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const CaseStatusReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const beginDate = dayjs(`${dayjs().year()}-01-01`)
    const [dateRange, setDateRange] = useState([beginDate, dayjs().subtract(1, 'days')])
    const [isLoading, setIsLoading] = useState(false)

    const MINDATE = dayjs("2022-01-01")
        
    const downloadReport = () => {
        // Validation. Date range cannot be more than 365 days
        const MAXDAYS = 366
        if(dateRange[1].diff(dateRange[0], 'day') > MAXDAYS) {
            message.info(`Report date range cannot be more than ${MAXDAYS} days`)
            return
        }

        if(dateRange[0].isBefore(MINDATE) || dateRange[1].isBefore(MINDATE)) {
            message.info(`Report date range must be ${MINDATE.format("YYYY-MM-DD")} onward.`)
            return
        }

        // Cannot cross year due to different excel template for each year.
        if(dateRange[0].year() != dateRange[1].year()) {
            message.info("Cross year not supported due to different excel report template for each year.")
            return
        }

        setIsLoading(true)
                        
        axios.get(`${APIURL}casestatusreport/`, {
            params: { 
                from_date: dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1].format(UNIDATEFORMAT),
            },
            responseType: "blob",
            timeout:  1200 * 1000, // 20 minutes
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const reportDate = dateRange[1].format(UNIDATECOMPACTFORMAT)
                FileDownload(response.data, `${reportDate} Case Status and Outstanding Cases.zip`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setDateRange([null, null])
        else
            setDateRange(dateRange)
    }

    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Note: Generated excel file will be labelled with Earliest Defect end date selected." key="1">
                        <Row justify="center">
                            <>
                            <Col span={6} />
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Earliest Defect Date:</Col>
                            <Col span={3} style={{ textAlign: 'start', marginRight: 20 }}>
                                <RangePicker allowClear={false} onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} 
                                    style={{ width: SEARCHOPTIONVIEWWIDTH }} 
                                    disabledFromDate={d => !d || d.isSameOrBefore(moment("2022-01-01").format(UNIDATEFORMAT))} 
                                    disabledToDate={d => !d || d.isSameOrBefore(moment("2022-01-01").format(UNIDATEFORMAT))} />
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={() => downloadReport()} icon={<FileExcelOutlined />} />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default CaseStatusReportTable